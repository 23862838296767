import React from "react"
import "./gridItem.css"
import { Link } from "gatsby"
import Image from "../image"

function GridItem({ index, name, img }) {
  return (
    <Link to={`/closeup/#${name}`} className="gridItem">
      <figure>
        <Image imgName={img} />
      </figure>
    </Link>
  )
}

export default GridItem
