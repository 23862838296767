import Grid from "../components/grid/grid"
import GridItem from "../components/gridItem/gridItem"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import friends from "../config/friends"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Kabayan" />
      <Grid>
        {friends.map((friend, i) => (
          <GridItem
            index={i}
            name={friend.name}
            img={friend.img}
            key={i.toString()}
          />
        ))}
      </Grid>
    </Layout>
  )
}

export default IndexPage
